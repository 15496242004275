import * as React from "react"

interface ISetting {
  ButtonColour: string
  ButtonTextColour: string
  HeaderBannerColour: string
  HeaderBannerLogo: string
  SMSCountryCode: string
  SMSTemplate: string
  HeaderBannerText: string
  ExpireTimeMins: number
}

interface IMetadata {
  jobId: string,
  time: string,
  resourceId: string
  jobName: string
  patientName: string
  token: string
}

interface IContext {
  setting: ISetting,
  metadata: IMetadata,
  setLoading?: Function
}

export default React.createContext<IContext | null>(null)