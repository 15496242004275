import "./style.scss"

import * as React from 'react'
import { useContext } from 'react'
import Context from "../context"

export default () => {
  const { setting } = useContext(Context)
  const { HeaderBannerText, HeaderBannerLogo, HeaderBannerColour } = setting;

  return (
    <header className="bar-title text-left pad-h header-wrapper" style={{ background: HeaderBannerColour }}>
      <img src={HeaderBannerLogo} alt="" /> {HeaderBannerText}
    </header>
  );
};