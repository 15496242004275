import * as React from 'react';
import { useState, useCallback, useRef, useLayoutEffect, useEffect, useContext } from 'react';
import axios from 'axios'
import SignaturePad from 'signature_pad'
import * as moment from "moment-timezone"
import Context from "./context"

interface IProps {
   dimension?: number
}

export default (props: IProps) => {
   const canvasRef = useRef(null)
   const [spad, setSpad] = useState();
   const [success, setSuccess] = useState<boolean>(false);
   const [rendered, setRendered] = useState<boolean>(false)

   const { metadata, setting, setLoading } = useContext(Context)
   let { ButtonColour, ButtonTextColour, ExpireTimeMins } = setting

   // @ts-ignore
   // ExpireTimeMins = 500
   const isLinkValid = React.useMemo(() => moment().isSameOrBefore(moment(metadata.time).add(ExpireTimeMins, "minute")), [metadata.time, ExpireTimeMins])

   const resizeCanvas = useCallback(() => {
      if (!canvasRef.current) {
         return
      }

      if (!isLinkValid) {
         // alert("Link is expire")
         // @ts-ignore
         spad.off();
         // @ts-ignore
         spad.clear();
         return
      }

      var ratio = Math.max(window.devicePixelRatio || 1, 1);
      // @ts-ignore
      canvasRef.current.width = canvasRef.current.offsetWidth * ratio;
      // @ts-ignore
      canvasRef.current.height = canvasRef.current.offsetHeight * ratio;
      // @ts-ignore
      canvasRef.current.getContext("2d").scale(ratio, ratio);
      // @ts-ignore
      spad.clear(); // otherwise isEmpty() might return incorrect value
   }, [spad, isLinkValid])

   useEffect(() => {
      if (!!rendered && isLinkValid && canvasRef.current && !success) {
         // @ts-ignore
         const canvas = new SignaturePad(canvasRef.current);
         // @ts-ignore
         setSpad(canvas)
         // setLoading(false);
      }

   }, [success, rendered, isLinkValid])

   useLayoutEffect(() => {
      if (spad) {
         window.addEventListener('resize', resizeCanvas);
         resizeCanvas()
      }
      return () => window.removeEventListener('resize', resizeCanvas);
   }, [spad, resizeCanvas]);

   const saveAttachments = useCallback(() => {
      // @ts-ignore
      if (!spad.isEmpty() && !!metadata && !!metadata.jobId) {
         const { jobId, resourceId, jobName, patientName, token } = metadata

         if (!isLinkValid) {
            // @ts-ignore
            spad.off()
            alert("Link is expire")
         } else {
            setLoading(true)
            axios.post(`/api/save-signature`, {
               // @ts-ignore
               sign: spad.toDataURL(),
               jobId, resourceId, jobName, patientName, token
            }).then(res => {
               // @ts-ignore
               spad.off()

               setSuccess(true)
            }).catch(error => console.log(error))
               .finally(() => setLoading(false))
         }
      }
   }, [spad, metadata, isLinkValid, setLoading])

   const testApi = () => {
      axios.get(`/api/ping`).then(res => console.log('GET', res))
   }

   const queryAttachment = useCallback(() => {
      const { jobId } = metadata
      axios.post(`/api/get-signature/${jobId}`, {
         token: metadata.token
      }).then(({ data }) => {
         console.log(data)
         if (!!data && data.length > 0) {
            const sign = data.find((item: any) => item.fileName.includes(`signature.png`) && item.description && item.description.includes(`contactless-signature`))
            if (sign) {
               setSuccess(!!sign.downloadUrl)
            }
         }

         setRendered(true)
      })
   }, [metadata])

   useEffect(() => {
      queryAttachment()
   }, [queryAttachment])

   const clearHandler = useCallback(() => {
      if (spad) {
         // @ts-ignore
         spad.clear();
      }
   }, [spad])

   if (!rendered) {
      return <div></div>
   }

   if (!isLinkValid) {
      return <div className="warning error">
         <div className="icon">
            <i className="fas fa-exclamation icon"></i>
         </div>
         <div className="message">Your link has expired.</div>
      </div>
   }

   return <div>
      {!success && (<div className="sign-wrapper">
         <div className={"content-section"}>
            <h2>Contactless signature requested</h2>

            <div className="color-darkgrey pad-v rm-pad-b">
               To continue to protect our community, customers and staff we are no longer asking you
               to provide your signature on our devices. Please enter your signature below.
            </div>
         </div>

         <div className="content-section signature-box-wrapper">
            <div className="signature-box">
               <canvas className="canvas" ref={canvasRef}></canvas>
               <div className="signature-text text-center">
                  <i className="fas fa-signature color-green text-medium"></i>
                  <span className="pad-l color-darkgrey">Please sign in the space provided above</span>
               </div>
            </div>
         </div>

         <div className="btn-wrapper">
            <div className="btn-group-2">
               <button className="btn btn--lightgray border-none" onClick={() => clearHandler()}>Clear</button>
               <button className="btn btn--green border-none" style={{ background: ButtonColour, color: ButtonTextColour }} onClick={() => saveAttachments()}>Save</button>
            </div>
         </div>
      </div>)}

      {success && (<div>
         <div className={"content-section"}>
            <h2>
               <i className="fas fa-check color-green"></i>
               <span className="pad-h">Signature Captured</span>
            </h2>

            <div className="color-darkgrey pad-v rm-pad-b">
               Your signature has been successfully captured.
               <br />
               Thank you for your support.
            </div>
         </div>
      </div>)}
   </div>
}