import "@fortawesome/fontawesome-free/css/all.css"

import * as React from 'react';
import { useState, useEffect } from 'react'
import Header from './header';
import Body from './body';
import Context from "./context";
import axios from 'axios'
import { Loading } from "@skedulo/custom-form-controls/dist/controls"

const decode32 = (encodeTxt) => {
  const decoder = new TextDecoder("utf-8");
  const base32Decode = require('base32-decode')
  const decode = base32Decode(encodeTxt, 'Crockford')
  const decodeText = decoder.decode(decode);
  return decodeText;
}

export default () => {
  const getDimension = (): number => {
    if (window.orientation !== 0) {
      return 1 // types.DIMENSION.landscape
    }
    return 0 // types.DIMENSION.portrait
  }

  const [dimension, setDimension] = useState<number>(getDimension())
  const [setting, setSetting] = useState({})
  const [loading, setLoading] = useState(false)
  const [loadSetting, setLoadSetting] = useState(false)

  useEffect(() => {
    const handleScreenRotate = () => {
      setDimension(getDimension());
    }

    window.addEventListener('orientationchange', handleScreenRotate, false);
    handleScreenRotate()

    return () => {
      window.removeEventListener('orientationchange', handleScreenRotate, false)
    }
  }, [])

  const metadata = React.useMemo(() => {
    const jobData = window.location.pathname.replace("/", "")
    const params: any = decode32(jobData);
    const { jobId, time, resourceId, jobName, patientName, token } = JSON.parse(params);
    return { jobId, time, resourceId, jobName, patientName, token };
  }, [])

  useEffect(() => {
    setLoading(true)
    axios.post(`/api/setting`, {
      token: metadata.token
    }).then(({ data }) => {
      setLoading(false)
      setSetting(data)
      setLoadSetting(true)
    })
  }, [metadata.token])

  if (!loadSetting) {
    return <div><Loading loading={loading} /></div>
  }

  // @ts-ignore
  return (<Context.Provider value={{ setting, metadata, setLoading }}>
    <Header />
    <section>

      <div>
        <Body dimension={dimension} />
      </div>
    </section>
    <Loading loading={loading} />
  </Context.Provider>
  );
}